@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

.home,
.IDO,
.products {
  display: flex;
  flex-direction: column;
  height: 90vh;
  align-items: center;
  /* justify-content: center; */
  font-size: 3rem;
  overflow-y: auto;
}

.homeBackground {
  background-image: url(./image/NewYear.png);
  background-size: 100% 100%;
  filter: blur(5px);
}

.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-color: wheat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#balance-btn {
  font-family: fantasy;
  color: black;
  position: fixed;
  top: 30px;
  right: 160px;
  background-color: wheat;
  width: 80px;
  height: 40px;
  border: 2px solid;
  border-color: black;
}

.tables {
  width: 400px;
  color: black;
}

.hr1 {
  color: black;
  position: fixed;
  top: 100px;
  right: 0px;
  width: 2000px;
  border: 2px solid;
}

#isConnected {
  position: fixed;
  top: 0px;
  left: 30px;
  color: red;
  flex: 1;
  justify-content: center;
}

.button {
  justify-content: center;
  align-items: center;
}