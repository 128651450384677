.countdownPage {
    background-color: #a01323;
    color: #efc75e;
}

.displayCountDown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    /* width: 90vw; */
    max-width: 700px;
    font-size: 18px;
    padding-bottom: 5vh;
}
.times {
    background-image: url(../image/backgroundTimer.png);
    border-radius: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(207, 17, 17);
    /* background-color: #d6192f; */
    width:8vw;
    max-width: 100px;
    height: 10vh;
    flex:1;
    border: 1px solid #efc75e;
}
.mark {
    margin-top: 5vh;
}